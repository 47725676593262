<template>
  <brand-header :image="urlImage" :altImage="altImagen"/>
  <header class="header">
    <div class="header-content max-container">
      <div class="header-content__nav">
        <router-link class="header-content__container-logo" to="/">
          <img class="header-content__img" src="../assets/images/logo-naranja.png" alt="betkings" />
        </router-link>
      </div>
      <div class="header-content__cta">
        <!-- <div class="container-lag">
              <div class="container-langs" v-if="this.$i18n.locale =='en'">
                <div class="lang" @click="changeLanguage('en')"> Eng </div>
                <div class="lang" @click="changeLanguage('es')"> Esp </div>
                <svg class="svg-line" height="70">
                  <line x1="0" y1="0" x2="0" y2="70" style="stroke:#5D77EB;stroke-width:5" />
                </svg>
              </div>
              <div class="container-langs" v-if="this.$i18n.locale =='es'">
                <div class="lang" @click="changeLanguage('es')"> Esp </div>
                <div class="lang" @click="changeLanguage('en')"> Eng </div>
                <svg class="svg-line" height="70">
                  <line x1="0" y1="0" x2="0" y2="70" style="stroke:#5D77EB;stroke-width:5" />
                </svg>
              </div>
              
              <svg class="flecha" xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
              <path d="M1 1L8 8L1 15" stroke="#5D77EB" stroke-width="2" stroke-linecap="round"/>
              </svg>
              Resto del contenido de tu componente 
        </div> -->
        <!-- <span class="header-content__language material-symbols-rounded">language</span> -->
        <div class="header-buttons">
          <form class="form-login form-desktop" method="post" :action="`//wager.${backendUrl}/DefaultLogin.aspx`">
            <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`"/>
            <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`"/> 
            <input type="text" :placeholder="$t('Username')" name="account" id="account">
            <input type="password" :placeholder="$t('Password')" name="password" id="password">
            <div id="errorM" v-if="urlLastPosition == '?login-error'" class="error">
              <p><strong>Incorrect user or password</strong></p>
            </div>
            <button class="btn-login secondary-button secondary-button__betslip" type="submit">{{$t('LOGIN')}}</button>
          </form>
          <form class="form-login form-mobile" method="post" :action="`//mobile.${backendUrl}/DefaultLogin.aspx`">
            <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`"/>
            <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`"/> 
            <input type="text" :placeholder="$t('Username')" name="account" id="account">
            <input type="password" :placeholder="$t('Password')" name="password" id="password">
            <div id="errorM" v-if="urlLastPosition == '?login-error'" class="error">
              <p><strong>Incorrect user or password</strong></p>
            </div>
            <button class="btn-login secondary-button secondary-button__betslip" type="submit">{{$t('LOGIN')}}</button>
          </form>
          <div class="container-bet-lag">
            <a href="#" class="btn-bet secondary-button secondary-button__betslip" @click="openModal">Betslip</a>
            
          </div>
          
        </div>
      </div>
    </div>
    <div class="header-content__info">
      <ul class="header-content__info--list">
        <li
        data-aos="zoom-in"
        data-aos-offset="-150"
        :data-aos-delay="100 * index"
        :data-aos-duration="800"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="false"
        data-aos-anchor-placement="top-center"
        class="header-content__info--list-item" @click="isActive = !isActive" v-for="(route, index) in routeshead" :key="index">
          <router-link :to="route.route">{{ route.item }}</router-link>
        </li>
      </ul>
    </div>
    <ModalComponent ref="modal" />
  </header>
</template>

<script>
  import ModalComponent from '@/components/modal.vue'
  import BrandHeader from '@/components/brand-header.vue';
  // import MenuComponent from '@/components/menu.vue'
  import { useI18n } from 'vue-i18n';
  import { reactive, watch } from 'vue';
  import {computed} from 'vue';

  export default {
    name: "HeaderComponent",
    components: {
      ModalComponent,
      BrandHeader
      // MenuComponent
    },
    watch: {
          $route(newVar){
          let pathName = computed(() =>newVar);
          this.detectLang(pathName.value.name);
        }
      },
    methods: {

      openModal() {
        this.$refs.modal.openModal();
      },
      changeLanguage(locale) {

        if(this.$i18n.locale!= locale){
          this.$i18n.locale = locale;
          const t = this.$t;
          const currentRoute = this.$router.currentRoute.value;

          if (currentRoute.path !== '/' && currentRoute.path !== '/apk') {
            const translatedPath = `/${t(`routes.${currentRoute.name}`)}`;
            this.$router.push(translatedPath);
          }
        }
        
      },
      getTranslatedRoutes(t) {
        
          const routes = this.$router.options.routes.map((route) => ({
            ...route,
            path: route.path !== '/' ? `/${t(`routes.${route.name}`)}` : '/',
          }));

          return routes;
        },
        detectLang(pathName){
          console.log(pathName);
          if(pathName.includes("langes")){
            this.$i18n.locale = "es";
            this.changeLanguage("es")
          }else if(pathName.includes("langen")){
            this.$i18n.locale = "en";
            this.changeLanguage("en")
          }else{
            console.log("default")
          }
      }
    },
    setup() {
      const DGS_SITEID = 1037;
      const backendUrl = "betkings888.ag";
      const { t , locale }  = useI18n();

      const actualUrl = window.location;
      const urlSlides = actualUrl.href.split('/');
      const urlLastPosition = urlSlides[urlSlides.length-1];

      const routeshead= reactive(
        [
          {
            item       :  t("routeshead.0.name"),
            route      :  t('routeshead.0.route')
          },
          {
            item       :  t('routeshead.1.name'),
            route      :  t('routeshead.1.route')
          },
          {
            item       :  t('routeshead.2.name'),
            route      :  t('routeshead.2.route')
          },
          {
            item       :  t('routeshead.3.name'),
            route      :  t('routeshead.3.route')
          },
          {
            item       :  t('routeshead.4.name'),
            route      :  t('routeshead.4.route')
          }
        ]
      ) 
       // Observar los cambios de idioma y actualiza los slides
       const updateSlides = () => {
        routeshead.forEach((routehead, index) => {
            routehead.item = t(`routeshead.${index}.name`);
            routehead.route = t(`routeshead.${index}.route`);
        });
      };

      // Escuchar los cambios de idioma y actualiza los slides
      watch(locale, () => {
        updateSlides();
      });

      return {
        DGS_SITEID,
        backendUrl,
        urlImage: require('../assets/images/logoblanco.png'),
        altImage: 'betkings',
        routeshead,
        urlLastPosition
        
      }
    },
  };
</script>
